import React, { useState } from 'react';
import './SummaryComponent.css';
import { FaFootballBall, FaGlobe, FaFilm, FaQuestion, FaHourglass, FaMusic } from 'react-icons/fa';
import UserSummary from './UserSummary';

const SummaryComponent = ({ completedCategories, onClose, answersByCategory, questionsData, totalPlayers, averageScore, score }) => {
  const initialExpandedState = Object.keys(questionsData).reduce((acc, category) => {
    acc[category] = false;
    return acc;
  }, {});

  const categoryIcons = {
    'History': <FaHourglass />,
    'Sports': <FaFootballBall />,
    'World': <FaGlobe />,
    'Music': <FaMusic />,
    'Entertainment': <FaFilm />,
    'Random': <FaQuestion />
  };

  const [expandedAnswers, setExpandedAnswers] = useState(initialExpandedState);

  const toggleAnswersVisibility = category => {
    setExpandedAnswers(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const trivialeStats = {
    gamesPlayed: totalPlayers, // Replace with dynamic value
    averageCircles: 2.7, // This value wasn't updated in the provided data, so keeping it as is
    averageScore: averageScore, // Replace with dynamic value
    winners: "tbd"
  };

  return (
    <div className="summary-modal">
      <div className="summary-box">
        <div className="summary-header">
          <h2>Summary</h2>
          <h2></h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <UserSummary
          completedCategories={completedCategories}
          categoryIcons={categoryIcons}
          questionsData={questionsData}
          answersByCategory={answersByCategory}
          score={score}
        />
        <div className="section-divider"></div>
        <div className="summary-header">
          <h2>Today's Triviale Stats</h2>
        </div>

        <div className="triviale-stats-container">
          <div className="stat">
            <span className="stat-title">Total Games</span>
            <span className="stat-number">{trivialeStats.gamesPlayed}</span>
          </div>
          <div className="stat">
            <span className="stat-title">Avg Score</span>
            <span className="stat-number">{trivialeStats.averageScore.toFixed(0)}</span>
          </div>
          <div className="stat">
            <span className="stat-title">Avg Circles</span>
            <span className="stat-number">{trivialeStats.averageCircles}</span>
          </div>
          <div className="stat">
            <span className="stat-title">Winners</span>
            <span className="stat-number">{trivialeStats.winners}</span>
          </div>
        </div>

        <div className="section-divider"></div>
        <div className="summary-header">
          <h2>Answers</h2>
        </div>

        {Object.entries(questionsData).map(([category, data], index) => (
          <div key={index}>
            <div className={`category-answer ${expandedAnswers[category] ? 'expanded' : ''}`}>
              <div className="category-icon">{categoryIcons[category]}</div>
              <div className="category-question">
                <div className="question-text">{data.question}</div>
              </div>
              {completedCategories.includes(category) && (
                <div className="answers-button" onClick={() => toggleAnswersVisibility(category)}>
                  Click to show 
                </div>
              )}

            </div>
            {expandedAnswers[category] && completedCategories.includes(category) && (
              <div className="all-answers-section">
                {Object.entries(questionsData[category].correctAnswers).map(([answer, data], idx) => (
                  <div key={idx} className="answer">{data.name} : {data.count}</div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummaryComponent;
