// SectionItem.js
import React, { useState } from 'react';
import './Guide.css';

const SectionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="section-item">
            <button className="section-button" onClick={toggle}>
                <h2 className="section-title">{title}</h2> {/* Use h2 for the title */}
                <span className={`section-icon ${isOpen ? 'active' : ''}`}></span>
            </button>
            {isOpen && <div className="section-content">{children}</div>}
        </div>
    );
};

export default SectionItem;
