import React from 'react';
import './Guide.css'; // Ensure you have the CSS for styling this component
import FaqItem from './FaqItem'; // Import the new FAQ item component
import SectionItem from './SectionItem'; // Import the new section item component


const Guide = ({ additionalClass }) => {
    const faqs = [
        {
            question: "How are scores determined?",
            answer: "Your score for each question is calcualted by subtracting your percentage from 100. If you get a question wrong, that is 0 points. If you are the only person to input a certain answer, you get 100 points. Total scores can range from 0-3600."
        },
        {
            question: "What do the percentages mean?",
            answer: "The percentage next to each answer indicates how many other players also chose that answer. If a number has 0%, it means no other players chose that answer."
        }, {
            question: "How does answer validation work?",
            answer: "For each possible answer, there is a list of strings that determine correct variations of that answer. Because of the nature of the game, I am going to miss possible inputs so please bear with me as I will be improving this system over the next few updates. I have also implemented an algorithm that helps alleviate typoed answers."
        }, {
            question: "Why is my score 0 even though my answer is correct?",
            answer: "Your score is calculated by determining how rare your answers are. If 100% of players used the same answer for a question, the points awarded would be 100 - 100, or 0 points"
        }
    ];
    return (
        <div className={`guide-container ${additionalClass}`}>
            <SectionItem title="How to Play">

                <p>For each Triviale category, write six answers. Complete all six categories to compelte the Triviale for the day!</p>
                <p>While getting all 6 answers correct will result in you winning the category, you should also try to give the rarest answer you can</p>
                <p>The score for each correct answer is based on the percentage of other players that also chose that answer. The higher your final score, the better.</p>
            </SectionItem>

            <SectionItem title="Example">
                <p>For the question "US States", some possible answers include "Colorado" and "Alabama" while "Canada" is incorrect and will result in 100 points added to your score</p>
                <p>All 50 states are correct answers, but the less popular answers will result in you getting a better score. If 45% of players choose New Jersey, 65 points will be added to your total score</p>
                <p>Click a category to begin!</p>
            </SectionItem>

            <SectionItem title="FAQ">
                {/* Map through your faqs array to render FaqItem components */}
                {faqs.map(faq => (
                    <FaqItem key={faq.question} question={faq.question} answer={faq.answer} />
                ))}
            </SectionItem>
        </div>
    );
};

export default Guide;