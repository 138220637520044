import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Navbar from './components/nav';
import Guide from './components/Guide'
import CategoryButton from './components/CategoryButton';
import QuestionComponent from './components/QuestionComponent';
import SummaryComponent from './components/SummaryComponent';
import FeedbackComponent from './components/FeedbackComponent';


const App = () => {
  const loadState = (key, defaultValue) => {
    const savedData = localStorage.getItem(key);
    return savedData ? JSON.parse(savedData) : defaultValue;
  };

  const defaultGuesses = {
    "History": 6,
    "Entertainment": 6,
    "Sports": 6,
    "World": 6,
    "Music": 6,
    "Random": 6,
  };

  const defaultScore = {
    "History": 0,
    "Entertainment": 0,
    "Sports": 0,
    "World": 0,
    "Music": 0,
    "Random": 0,
  };

  const [selectedCategory, setSelectedCategory] = useState(loadState('selectedCategory', null));
  const [answersByCategory, setAnswersByCategory] = useState(loadState('answersByCategory', {}));
  const [completedCategories, setCompletedCategories] = useState(loadState('completedCategories', []));
  const [scoreSubmitted, setScoreSubmitted] = useState(() => JSON.parse(localStorage.getItem('scoreSubmitted')) || false);
  const [score, setScore] = useState(loadState('score', 0));
  const [guessesRemainingByCategory, setGuessesRemainingByCategory] = useState(() => loadState('guessesRemainingByCategory', defaultGuesses));
  const [scoreByCategory, setCategoryScore] =  useState(() => loadState('scoreByCategory', defaultScore));


  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isGuideLeft, setLeftGuide] = useState(loadState('isGuideLeft', false));
  const [isGuideOpen, setIsGuideOpen] = useState(loadState('isGuideOpen', true));
  const [questionsData, setQuestionsData] = useState({});
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [averageScore, setAverageScore] = useState(0);
  const [day, setDay] = useState(0);

  useEffect(() => {
    localStorage.setItem('selectedCategory', JSON.stringify(selectedCategory));
    localStorage.setItem('guessesRemainingByCategory',JSON.stringify(guessesRemainingByCategory));
    localStorage.setItem('scoreByCategory',JSON.stringify(scoreByCategory));
    localStorage.setItem('scoreSubmitted', JSON.stringify(scoreSubmitted));
    localStorage.setItem('answersByCategory', JSON.stringify(answersByCategory));
    localStorage.setItem('completedCategories', JSON.stringify(completedCategories));
    localStorage.setItem('score', JSON.stringify(score));
    localStorage.setItem('isGuideLeft', JSON.stringify(isGuideLeft));
    localStorage.setItem('isGuideOpen', JSON.stringify(isGuideOpen)); // Add this line
  }, [scoreSubmitted, selectedCategory, answersByCategory, completedCategories, score,scoreByCategory, isGuideLeft, isGuideOpen, guessesRemainingByCategory]); // Add isGuideOpen to the dependencies array

  useEffect(() => {
    if (Object.keys(questionsData).length > 0 && completedCategories.length === 6) {
      updateScores();
    }
  }, [questionsData, totalPlayers]);

  useEffect(() => {
    fetch('/api/questions')
      .then(response => response.json())
      .then(data => setQuestionsData(data))
      .catch(error => console.error('Error fetching questionsData:', error));
    // Fetch game stats
    fetch('/api/stats')
      .then(response => response.json())
      .then(data => {
        const currentDay = data.day;
        const storedDay = loadState('day', null);

        if (currentDay !== storedDay) {
          setScoreSubmitted(false);
          setSelectedCategory(null);
          setAnswersByCategory({});
          setCompletedCategories([]);
          setGuessesRemainingByCategory(defaultGuesses)
          setCategoryScore(defaultScore)
          setScore(0);
          setLeftGuide(false); 
          setIsGuideOpen(true);
          localStorage.setItem('day', JSON.stringify(currentDay));
        }

        setTotalPlayers(data.totalPlayers);
        setAverageScore(data.averageScore);
        setDay(data.day);

      })
      .catch(error => console.error('Error fetching game stats:', error));
  }, []);

  // Effect to log and handle game completion based on completedCategories length
  useEffect(() => {
    if (completedCategories.length === 6) {
      handleFinishGame();
    }
  }, [completedCategories]);

  const handleOpenSummary = () => setIsSummaryOpen(!isSummaryOpen);
  const toggleGuide = () => {
    setIsGuideOpen(!isGuideOpen);
  };
  const handleOpenFeedback = () => {
    setIsFeedbackOpen(true);
  };

  const handleCloseFeedback = () => {
    setIsFeedbackOpen(false);
  };

  const handleFinishGame = () => {
    handleOpenSummary();

    if (!scoreSubmitted) {
      fetch('/api/stats', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newScore: score, completedCategories }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Final score recorded:', data.message);
          setScoreSubmitted(true);
        })
        .catch(error => console.error('Error recording final score:', error));
    }
  };


  const handleCategoryCompleted = (category, newScore) => {
    setCompletedCategories(prevCategories => {
      if (!prevCategories.includes(category)) {
        return [...prevCategories, category];
      }
      return prevCategories;
    });
    setScore(score + newScore)
  };

  const handleNewAnswer = (name, percentage, isCorrect) => {
    if (isCorrect) {
      setAnswersByCategory(prev => ({
        ...prev,
        [selectedCategory]: [...(prev[selectedCategory] || []), { answer: name, isCorrect, percentage: percentage }],
      }));
      const postData = { category: selectedCategory, answer: name, isCorrect };

      fetch('/api/answer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Answer recorded:', data.message);
        })
        .catch(error => console.error('Error recording answer:', error));
    }
    else {
      setAnswersByCategory(prev => ({
        ...prev,
        [selectedCategory]: [...(prev[selectedCategory] || []), { answer: name, isCorrect, percentage: percentage }],
      }));
    }
  };

  const handleCategoryClick = (category) => {
    if (!selectedCategory) { // Check if this is the first category being selected
      setLeftGuide(true); // Move the guide to the left permanently
      toggleGuide() // Hide the guide immediately upon first category selection
    }
    setSelectedCategory(category);
    setCurrentQuestion(questionsData[category]?.question);
  };



  const calculateCorrectAnswers = (category) => {
    return (answersByCategory[category] || []).filter(answer => answer.isCorrect).length;
  };

  const updateScores = () => {
    const updatedAnswersByCategory = { ...answersByCategory };
    Object.keys(updatedAnswersByCategory).forEach(category => {
      updatedAnswersByCategory[category] = updatedAnswersByCategory[category].map(answerObj => {
        const correctAnswers = questionsData[category]?.correctAnswers || [];
        let newPercentage = 100; // Default percentage if the answer does not match any correct answer
        for (let i = 0; i < correctAnswers.length; i++) {
          newPercentage = getNewPercentage(answerObj.answer, category)
        }
        return {
          ...answerObj,
          percentage: (newPercentage)
        };
      });
    });

    setAnswersByCategory(updatedAnswersByCategory);
  };

  function getNewPercentage(answer, category) {
    const correctAnswers = questionsData[category]?.correctAnswers || [];
    const trimmedAndLowercasedAnswer = answer.toLowerCase().trim();
    for (var i = 0; i < correctAnswers.length; i++) {
      if (correctAnswers[i].options.map(option => option.toLowerCase().trim()).includes(trimmedAndLowercasedAnswer)) {
        var percentage = 100 * (correctAnswers[i].count - 1 / totalPlayers)
        if (percentage === Infinity || isNaN(percentage)) {
          return 0
        }
        if (percentage >= 100) {
          return 100
        }
        return percentage.toFixed(2);
      }
    }
    return 100;
  }


  return (
    <div className="App">
      <Navbar
        onOpenSummary={handleOpenSummary}
        onOpenGuide={toggleGuide}
        onOpenFeedback={handleOpenFeedback}
      />
      <p style={{ fontSize: '12px', padding: '2vh' }}>New games release Sunday-Thursday at 12am</p>
      <div className="category-container">
        {Object.keys(questionsData).map((category) => (
          <CategoryButton
            key={category}
            label={category}
            onClick={() => handleCategoryClick(category)}
            isSelected={selectedCategory === category}
            progress={calculateCorrectAnswers(category)}
          />
        ))}
      </div>
      {isGuideLeft ? (
        <>
          <QuestionComponent
            question={currentQuestion}
            guessesRemainingByCategory={guessesRemainingByCategory}
            setGuessesRemainingByCategory={setGuessesRemainingByCategory}
            scoreByCategory={scoreByCategory}
            setCategoryScore={setCategoryScore}
            onSubmitAnswer={handleNewAnswer}
            answers={answersByCategory[selectedCategory] || []}
            data={questionsData}
            currentCategory={selectedCategory}
            completedCategories={completedCategories}
            onCategoryCompleted={handleCategoryCompleted}
            totalPlayers={totalPlayers}
            answersByCategory={answersByCategory}
            day = {day}

          />
          <Guide additionalClass={`${isGuideOpen ? 'guide-visible' : ''} ${isGuideLeft ? 'guide-left' : 'guide-center'}`} />
        </>
      ) : (
        <>
          <Guide additionalClass={`${isGuideOpen ? 'guide-visible' : ''} ${isGuideLeft ? 'guide-left' : 'guide-center'}`} />
          {selectedCategory && (
            <QuestionComponent
              question={currentQuestion}
              guessesRemainingByCategory = {guessesRemainingByCategory}
              setGuessesRemainingByCategory = {setGuessesRemainingByCategory}
              scoreByCategory = {scoreByCategory}
              setCategoryScore = {setCategoryScore}
              onSubmitAnswer={handleNewAnswer}
              answers={answersByCategory[selectedCategory] || []}
              data={questionsData}
              currentCategory={selectedCategory}
              completedCategories={completedCategories}
              onCategoryCompleted={handleCategoryCompleted}
              totalPlayers={totalPlayers}
            />
          )}
        </>
      )}
      {isSummaryOpen && (
        <SummaryComponent
          onClose={() => setIsSummaryOpen(false)}
          answersByCategory={answersByCategory}
          completedCategories={completedCategories}
          questionsData={questionsData}
          totalPlayers={totalPlayers} // Pass total players
          averageScore={averageScore} // Pass average score
          score={score} // Conditionally pass score or null
        />
      )}
      {isFeedbackOpen && (
        <FeedbackComponent onClose={handleCloseFeedback} />
      )}

    </div>
  );
};

export default App;