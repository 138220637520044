import React from 'react';
import './QuestionComponent.css';

const messages = {
    History: {
        0: "Hit the history books a little more",
        1: "At least you knew something",
        2: "This is a respectable score",
        3: "I would give you a B on a history essay",
        4: "One or two more documentaries and you're a master",
        5: "I commend you on your effort",
        6: "Congratulations! You've mastered the History category!",
    },
    Sports: {
        0: "Looks like it's back to the drawing board, but don't give up!",
        1: "One right answer is a start, every champion was once a contender.",
        2: "Showing promise! You're getting into the game.",
        3: "Nice! You're playing the field well.",
        4: "Impressive! You're a real sports enthusiast.",
        5: "So close to a championship! Great job!",
        6: "Congratulations! You've achieved a perfect score in Sports!",
    },

    Entertainment: {
        0: "Every show must start somewhere. Why not another round?",
        1: "Caught the opening act! Let's see what comes next.",
        2: "You're tuned in! Keep up the good work.",
        3: "You're getting the hang of this entertainment gig.",
        4: "An entertainment enthusiast in the making!",
        5: "Just one step away from entertainment mastery!",
        6: "Congratulations! You're the star of the Entertainment category!",
    },

    World: {
        0: "The world is vast and full of wonders. Keep exploring!",
        1: "You've taken your first step on a global journey.",
        2: "You're on your way to becoming a world traveler.",
        3: "Impressive! You're broadening your horizons.",
        4: "You have a keen sense of the world around you.",
        5: "Almost a global expert! Great work!",
        6: "Congratulations! You're a true citizen of the world!",
    },

    Random: {
        0: "The wheel of fortune spins in mysterious ways. Try again!",
        1: "One random fact down, many more to go!",
        2: "You're catching on to the randomness!",
        3: "Randomly accurate and getting better!",
        4: "A connoisseur of random trivia, I see!",
        5: "So close to mastering the art of randomness!",
        6: "Congratulations! You've conquered the Random category!",
    },
    Music: {
        0: "Maybe this isn't your genre",
        1: "You need some HELP!",
        2: "Turn off the podcasts and listen to some music!",
        3: "Just about average",
        4: "Almost there!",
        5: "Ice Spice made it to 3rd on the Billboard Hot 100 btw",
        6: "Congratulations! You've won the Music category!",
    },

};

const Message = ({ score, numAnswers, currentCategory }) => {
    const getMessage = (numAnswers, currentCategory) => {
        return messages[currentCategory][numAnswers] || "An unexpected combination!";
    };


    return (
        <div className="message-container">
            {score != undefined && (
                <div className="message-score">Rarity Score: {score.toFixed(0)}</div>
            )}
            <div className="congrats-message">
                {getMessage(numAnswers, currentCategory)}
            </div>
        </div>
    );
};

export default Message;
