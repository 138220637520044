import React from 'react';
import './CategoryButton.css'; // Make sure to update your CSS file accordingly
import { FaHourglass, FaFootballBall, FaGlobe, FaMusic, FaFilm, FaQuestion } from 'react-icons/fa'; // Import icons for categories

const CategoryButton = ({ label, onClick, progress, isSelected }) => {
  console.log(isSelected)
  // Define the icons for each category
  const buttonClasses = `category-button ${isSelected ? 'selected' : ''}`;

  const icons = {
    History: <FaHourglass />,
    Sports: <FaFootballBall />,
    World: <FaGlobe />,
    Music: <FaMusic />,
    Entertainment: <FaFilm />,
    Random: <FaQuestion />,
  };

  // Calculate the fill height based on the progress
  const fillHeight = `${(progress / 6) * 100}%`;

  return (
    <button className={buttonClasses} onClick={() => onClick(label)}>
      <div className="category-fill" style={{ height: fillHeight }}></div>
      {icons[label]}
    </button>
  );
};

export default CategoryButton;