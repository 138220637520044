import React from 'react';
import { FaEnvelope, FaQuestionCircle, FaChartBar } from 'react-icons/fa';
import './nav.css'; 

const Nav = ({ onOpenSummary, onOpenGuide, onOpenFeedback }) => { 
  return (
    <nav className="navbar">
      <h1 className="navbar-title">TRIVIALE</h1>
      <div className="navbar-icons">
        <FaEnvelope className="icon" onClick={onOpenFeedback} />
        <FaQuestionCircle className="icon" onClick={onOpenGuide} />
        <FaChartBar className="icon" onClick={onOpenSummary} /> 
      </div>
    </nav>
  );
};

export default Nav;
