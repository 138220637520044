import React, { useState } from 'react';
import './SummaryComponent.css'; // Ensure you have the CSS for styling this component

const UserSummary = ({ completedCategories, categoryIcons, questionsData, answersByCategory, score }) => {
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(null);
  const [wonCategories, setWonCategories] = useState([]);

  const handleCategorySquareClick = (category, index) => {
    setActiveCategoryIndex(activeCategoryIndex === index ? null : index);
  };

  const getSquareColor = categoryName => {
    const answers = answersByCategory[categoryName] || [];
    const correctCount = answers.filter(answer => answer.isCorrect).length;
    const incorrectCount = answers.length - correctCount;
    if (correctCount === 6) {

      return 'green';
    }
    if (correctCount < 6 && completedCategories.includes(categoryName)) {
      return 'red';
    }
    if (correctCount > 0 || incorrectCount > 0) {
      return '#FFC300';
    }
    return 'var(--primary-color)';
  };
  const calculateWonCategories = () => {
    let wonCategoriesCount = 0;

    Object.keys(answersByCategory).forEach(categoryName => {
      const answers = answersByCategory[categoryName];
      const correctCount = answers.filter(answer => answer.isCorrect).length;

      if (correctCount === 6) {
        wonCategoriesCount += 1;
      }
    });

    return wonCategoriesCount;
  };

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Optional: Display a confirmation message or perform some action after successfully copying
      console.log('Text copied to clipboard');
    }).catch(err => {
      // Optional: Display an error message or perform some action in case of a failure
      console.error('Failed to copy text: ', err);
    });
  };

  const wonCategoriesCount = calculateWonCategories();
  const emojiDisplay = wonCategoriesCount > 0 
                        ? Array(wonCategoriesCount).fill('🟢').join('') // Green apples for categories won
                        : '❌'; // Red X if no categories won

  return (
    <div>
      <div className="squares-container">
        {Object.keys(questionsData).map((category, index) => (
          <div
            key={index}
            className={`summary-square ${index === activeCategoryIndex ? 'selected' : ''}`}
            style={{ backgroundColor: getSquareColor(category) }}
            onClick={() => handleCategorySquareClick(category, index)}>
            {categoryIcons[category]}
          </div>
        ))}
      </div>
      {activeCategoryIndex !== null && (
        <div className="user-answers-section">
          {(answersByCategory[Object.keys(questionsData)[activeCategoryIndex]] || []).map((answerObj, idx) => (
            <div key={idx} className={`user-answer-summary`} style={{ backgroundColor: answerObj.isCorrect ? 'green' : 'red' }}>
              {answerObj.answer}
            </div>
          ))}
        </div>
      )}
      {completedCategories.length === 6 && (
        <div className="score-container">
          {score !== null && (
            <h3>Rarity Score: {score.toFixed(0)}</h3>
          )}
          <button onClick={() => copyToClipboard(`Triviale 001 Categories Won: ${wonCategoriesCount > 0 ? wonCategoriesCount : 'None'} ${emojiDisplay}, Rarity Score: ${score.toFixed(0)} Play at: https://triviale.me`)}>Copy</button>
        </div>
      )}
    </div>
  );
};

export default UserSummary;
