import React, { useState, useEffect } from 'react';
import './QuestionComponent.css';
import Message from './Message';

const QuestionComponent = ({
  question,
  guessesRemainingByCategory,
  setGuessesRemainingByCategory,
  scoreByCategory,
  setCategoryScore,
  onSubmitAnswer,
  answers,
  data,
  currentCategory,
  completedCategories,
  onCategoryCompleted,
  totalPlayers,
}) => {


  const [inputValue, setInputValue] = useState('');
  const correctAnswersCount = answers.filter(answer => answer.isCorrect).length;
  const isCategoryComplete = completedCategories.includes(currentCategory);


  useEffect(() => {
    if (guessesRemainingByCategory[currentCategory] === 0 && !completedCategories.includes(currentCategory)) {
      onCategoryCompleted(currentCategory, scoreByCategory[currentCategory]);
    }
  }, [guessesRemainingByCategory, currentCategory, completedCategories, onCategoryCompleted]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleGiveUp = () => {
    const confirmGiveUp = window.confirm("Are you sure you want to give up this category");
    if (confirmGiveUp) {
      const guessesLeft = guessesRemainingByCategory[currentCategory] ?? 6;
      for (var i = 0; i < guessesLeft; i++) {
        onSubmitAnswer("", 100, false);
      }
      setGuessesRemainingByCategory(prev => ({
        ...prev,
        [currentCategory]: 0,
      }));
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAnswerSubmission(inputValue.trim());
    }
  };

  const handleAnswerSubmission = (answer) => {
    const trimmedAnswer = answer.trim();
    if (trimmedAnswer && (guessesRemainingByCategory[currentCategory] ?? 6) > 0) {
      var name = validateAnswer(trimmedAnswer)
      if (name === null) {
        var isAlreadyAnswered = answers.some(ans => ans.answer.toLowerCase() === trimmedAnswer.toLowerCase());
        if (!isAlreadyAnswered) {
          onSubmitAnswer(answer, 100, false);
          setGuessesRemainingByCategory(prev => ({
            ...prev,
            [currentCategory]: (prev[currentCategory] ?? 6) - 1,
          }));
        }
      }
      else {
        isAlreadyAnswered = answers.some(ans => ans.answer.toLowerCase() === name.toLowerCase());
        if (!isAlreadyAnswered) {
          const percentage = calculatePercentage(name);
          onSubmitAnswer(name, percentage, true);
          setGuessesRemainingByCategory(prev => ({
            ...prev,
            [currentCategory]: (prev[currentCategory] ?? 6) - 1,
          }));
          setCategoryScore(prev => ({
            ...prev,
            [currentCategory]: (prev[currentCategory] ?? 0) + (100 - percentage),
          }));
        }
      }
      setInputValue('');
    }
  }

  function validateAnswer(answer) {
    const correctAnswers = data[currentCategory]?.correctAnswers || [];
    const trimmedAndLowercasedAnswer = answer.toLowerCase().trim();
    for (let i = 0; i < correctAnswers.length; i++) {
      for (let j = 0; j < correctAnswers[i].options.length; j++) {
        const optionTrimmedLowercased = correctAnswers[i].options[j].toLowerCase().trim();
        if (optionTrimmedLowercased === trimmedAndLowercasedAnswer) {
          return correctAnswers[i].name;
        }
      };
    }
    const tolerance = 1;
    for (let i = 0; i < correctAnswers.length; i++) {
      for (let j = 0; j < correctAnswers[i].options.length; j++) {
        const optionTrimmedLowercased = correctAnswers[i].options[j].toLowerCase().trim();
        const distance = levenshteinDistance(trimmedAndLowercasedAnswer, optionTrimmedLowercased);
        if (distance <= tolerance) {
          return correctAnswers[i].name;
        }
      };
    }
    return null
  }

  function calculatePercentage(name) {
    var count = data[currentCategory].correctAnswers.find(answer => answer.name.toLowerCase() === name.toLowerCase()).count
    var percentage = 100 * (count / totalPlayers)
    if (percentage === Infinity || isNaN(percentage)) {
      return 0
    }
    if (percentage >= 100) {
      return 100
    }
    return percentage.toFixed(2);
  }

  function levenshteinDistance(s1, s2) {
    const track = Array(s2.length + 1).fill(null).map(() => Array(s1.length + 1).fill(null));
    for (let i = 0; i <= s1.length; i += 1) {
      track[0][i] = i;
    }
    for (let j = 0; j <= s2.length; j += 1) {
      track[j][0] = j;
    }
    for (let j = 1; j <= s2.length; j += 1) {
      for (let i = 1; i <= s1.length; i += 1) {
        const indicator = s1[i - 1] === s2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
          track[j][i - 1] + 1, // insertion
          track[j - 1][i] + 1, // deletion
          track[j - 1][i - 1] + indicator, // substitution
        );
      }
    }
    return track[s2.length][s1.length];
  }

  return (
    <div className="question-component">
      <div className="question-container"><h2 className="question">{question}</h2></div>
      {isCategoryComplete ? (
        <Message score={scoreByCategory[currentCategory]} numAnswers={correctAnswersCount} currentCategory={currentCategory} />
      ) : (
        <>
          {guessesRemainingByCategory[currentCategory] > 0 && (
            <div className="input-container">
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                placeholder="Type a word"
                className="word-input"
                disabled={guessesRemainingByCategory[currentCategory] === 0}
              />
            </div>
          )}
        </>
      )}
      <div className="answers-list">
        {answers.filter(answerObj => answerObj.answer !== "").map((answerObj, index) => (
          <div key={index} className="answers-container">
            <div
              className={`answer-item ${answerObj.isCorrect ? 'correct' : 'incorrect'}`}
              style={{ backgroundColor: answerObj.isCorrect ? 'green' : 'red' }}>
              <span className="word">{answerObj.answer}</span>
              {answerObj.isCorrect && (
                <span className="percentage">{answerObj.percentage}%</span>
              )}
            </div>
          </div>
        ))}
      </div>

      {guessesRemainingByCategory[currentCategory] > 0 && guessesRemainingByCategory[currentCategory] < 6 && !isCategoryComplete && (
        <div className="give-up-wrapper">
          <button className="give-up" onClick={handleGiveUp}>Give Up</button>
        </div>
      )}
    </div>

  );
};

export default QuestionComponent;