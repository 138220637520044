import React, { useState } from 'react';
import './Feedback.css'; // Ensure you have the CSS for styling this component

const FeedbackComponent = ({ onClose }) => {
    const [questionSuggestion, setQuestionSuggestion] = useState('');
    const [improvements, setImprovements] = useState('');
    const [bugReport, setBugReport] = useState('');

    const handleSubmit = () => {
        fetch('/api/feedback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questionSuggestion, improvements, bugReport }),
        })
        .then(response => response.text())
        .then(data => {
            console.log('Success:', data);
            onClose();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };
    

    return (
        <div className="feedback-container">
            <h2>Feedback</h2>
            <div className="feedback-section">
                <h3>Question Suggestions</h3>
                <textarea
                    value={questionSuggestion}
                    onChange={(e) => setQuestionSuggestion(e.target.value)}
                    placeholder="Suggest new questions or categories..."
                />
            </div>
            <div className="feedback-section">
                <h3>Improvements</h3>
                <textarea
                    value={improvements}
                    onChange={(e) => setImprovements(e.target.value)}
                    placeholder="How can we improve Triviale?"
                />
            </div>
            <div className="feedback-section">
                <h3>Bug Reporting</h3>
                <textarea
                    value={bugReport}
                    onChange={(e) => setBugReport(e.target.value)}
                    placeholder="Report any bugs or issues..."
                />
            </div>
            <div className="feedback-buttons">
                <button onClick={handleSubmit} className="submit-button">Submit Feedback</button>
                <button onClick={onClose} className="close-button">Close</button>
            </div>
        </div>
    );
};

export default FeedbackComponent;
