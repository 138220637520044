import React, { useState } from 'react';
import './Guide.css'; // Ensure you have the CSS for styling this component

const FaqItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="faq-item">
            <button className="faq-button" onClick={toggle}>
                <span className="faq-question">{question}</span> {/* FAQ Question */}
                <span className={`faq-icon ${isOpen ? 'active' : ''}`}></span>
            </button>
            {isOpen && <div className="faq-answer">{answer}</div>} {/* FAQ Answer */}
        </div>
    );
};

export default FaqItem;
